module.exports = `
<!DOCTYPE html>
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ul.lst-kix_sqzutnni33pn-4 {
            list-style-type: none
        }

        ul.lst-kix_sqzutnni33pn-3 {
            list-style-type: none
        }

        ul.lst-kix_sqzutnni33pn-2 {
            list-style-type: none
        }

        ul.lst-kix_sqzutnni33pn-1 {
            list-style-type: none
        }

        ul.lst-kix_sqzutnni33pn-0 {
            list-style-type: none
        }

        ul.lst-kix_sqzutnni33pn-8 {
            list-style-type: none
        }

        ul.lst-kix_sqzutnni33pn-7 {
            list-style-type: none
        }

        ul.lst-kix_sqzutnni33pn-6 {
            list-style-type: none
        }

        ul.lst-kix_sqzutnni33pn-5 {
            list-style-type: none
        }

        .lst-kix_in5adzbckjmt-1>li:before {
            content: "25cb  "
        }

        .lst-kix_in5adzbckjmt-0>li:before {
            content: "25cf  "
        }

        ul.lst-kix_hyjzp0usaoaq-4 {
            list-style-type: none
        }

        ul.lst-kix_hyjzp0usaoaq-5 {
            list-style-type: none
        }

        ul.lst-kix_hyjzp0usaoaq-6 {
            list-style-type: none
        }

        ul.lst-kix_hyjzp0usaoaq-7 {
            list-style-type: none
        }

        ul.lst-kix_hyjzp0usaoaq-8 {
            list-style-type: none
        }

        .lst-kix_sqzutnni33pn-2>li:before {
            content: "25a0  "
        }

        .lst-kix_sqzutnni33pn-4>li:before {
            content: "25cb  "
        }

        .lst-kix_sqzutnni33pn-1>li:before {
            content: "25cb  "
        }

        .lst-kix_sqzutnni33pn-5>li:before {
            content: "25a0  "
        }

        .lst-kix_sqzutnni33pn-0>li:before {
            content: "25cf  "
        }

        .lst-kix_sqzutnni33pn-6>li:before {
            content: "25cf  "
        }

        .lst-kix_sqzutnni33pn-8>li:before {
            content: "25a0  "
        }

        ul.lst-kix_hyjzp0usaoaq-0 {
            list-style-type: none
        }

        ul.lst-kix_hyjzp0usaoaq-1 {
            list-style-type: none
        }

        ul.lst-kix_hyjzp0usaoaq-2 {
            list-style-type: none
        }

        ul.lst-kix_hyjzp0usaoaq-3 {
            list-style-type: none
        }

        .lst-kix_sqzutnni33pn-7>li:before {
            content: "25cb  "
        }

        .lst-kix_in5adzbckjmt-2>li:before {
            content: "25a0  "
        }

        .lst-kix_in5adzbckjmt-3>li:before {
            content: "25cf  "
        }

        .lst-kix_in5adzbckjmt-5>li:before {
            content: "25a0  "
        }

        .lst-kix_in5adzbckjmt-4>li:before {
            content: "25cb  "
        }

        .lst-kix_hyjzp0usaoaq-6>li:before {
            content: "25cf  "
        }

        .lst-kix_in5adzbckjmt-7>li:before {
            content: "25cb  "
        }

        .lst-kix_hyjzp0usaoaq-5>li:before {
            content: "25a0  "
        }

        .lst-kix_hyjzp0usaoaq-7>li:before {
            content: "25cb  "
        }

        .lst-kix_in5adzbckjmt-6>li:before {
            content: "25cf  "
        }

        .lst-kix_hyjzp0usaoaq-2>li:before {
            content: "25a0  "
        }

        ul.lst-kix_in5adzbckjmt-7 {
            list-style-type: none
        }

        ul.lst-kix_in5adzbckjmt-8 {
            list-style-type: none
        }

        .lst-kix_hyjzp0usaoaq-1>li:before {
            content: "25cb  "
        }

        .lst-kix_hyjzp0usaoaq-0>li:before {
            content: "25cf  "
        }

        .lst-kix_hyjzp0usaoaq-8>li:before {
            content: "25a0  "
        }

        ul.lst-kix_in5adzbckjmt-3 {
            list-style-type: none
        }

        ul.lst-kix_in5adzbckjmt-4 {
            list-style-type: none
        }

        li.li-bullet-0:before {
            margin-left: -18pt;
            white-space: nowrap;
            display: inline-block;
            min-width: 18pt
        }

        ul.lst-kix_in5adzbckjmt-5 {
            list-style-type: none
        }

        .lst-kix_in5adzbckjmt-8>li:before {
            content: "25a0  "
        }

        ul.lst-kix_in5adzbckjmt-6 {
            list-style-type: none
        }

        ul.lst-kix_in5adzbckjmt-0 {
            list-style-type: none
        }

        ul.lst-kix_in5adzbckjmt-1 {
            list-style-type: none
        }

        ul.lst-kix_in5adzbckjmt-2 {
            list-style-type: none
        }

        .lst-kix_hyjzp0usaoaq-3>li:before {
            content: "25cf  "
        }

        .lst-kix_hyjzp0usaoaq-4>li:before {
            content: "25cb  "
        }

        .lst-kix_sqzutnni33pn-3>li:before {
            content: "25cf  "
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c6 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Verdana";
            font-style: normal
        }

        .c0 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Verdana";
            font-style: normal
        }

        .c12 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Arial";
            font-style: normal
        }

        .c2 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Verdana";
            font-style: normal
        }

        .c11 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 20pt;
            font-family: "Verdana";
            font-style: normal
        }

        .c1 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c8 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c10 {
            color: #ff0000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-style: normal
        }

        .c14 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 10pt;
            font-style: normal
        }

        .c7 {
            color: #3c78d8;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-style: normal
        }

        .c15 {
            background-color: #ffffff;
            max-width: 540pt;
            padding: 36pt 36pt 36pt 36pt
        }

        .c13 {
            font-weight: 700;
            font-family: "Verdana"
        }

        .c5 {
            margin-left: 36pt;
            padding-left: 0pt
        }

        .c3 {
            font-weight: 400;
            font-family: "Verdana"
        }

        .c9 {
            padding: 0;
            margin: 0
        }

        .c4 {
            height: 11pt
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c15 doc-content">
    <p class="c8"><span class="c11">NearBy</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c6">Customer Terms &amp; Conditions</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">Personal Information</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c0">&quot;NearBy&quot; is a trademark of AinGlobe Technology Private Limited (hereinafter
            the &quot;Company&quot;), a company incorporated under the Companies Act, 2013 with its registered office at
            1/675 - A, Bhagavath Singh Colony, Kaithari Nagar, Nilaiyur, Madurai, TamilNadu - 625005. The domain name
            www.nearbyu.in is owned by the Company.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">It is strongly recommended that you read and understand these &#39;Terms of Use/
            Terms and Conditions&#39; carefully, as by accessing this site or any of NearBy&#39;s or it&#39;s brand
            mobile applications (hereinafter the &quot;Marketplace&quot;), you agree to be bound by the same and
            acknowledge that it constitutes an agreement between you and the Company (hereinafter the &quot;User
            Agreement&quot;). If you do not agree with this User Agreement, you should not use or access the Marketplace
            for any purpose whatsoever.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">This document is published in accordance with the provisions of Rule 3 of the
            Information Technology (Intermediaries Guidelines) Rules, 2011. The User Agreement may be updated from time
            to time by the Company without notice. It is therefore strongly recommended that you review the User
            Agreement, as available on the Marketplace, each time you access and/or use the Marketplace.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">The terms &#39;visitor(s)&#39;, &#39;user(s)&#39;, Customer(s), Consumer(s),
            &#39;you&#39; hereunder refer to the person visiting, accessing, browsing through and/or using the
            Marketplace at any point in time.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Should you have any clarifications regarding the Terms of Use, please do not hesitate
            to contact us at support@nearbyu.in.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c6">Services Overview</span></p>
    <p class="c1 c4"><span class="c3 c7"></span></p>
    <p class="c1"><span class="c13">Marketplace ordering</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c0">The marketplace is such a great access zone to display their store information,
            products and offers ete., To the customers as well as to transact with third party sellers / merchants and
            retailers to purchase from their store . </span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">The concerned company does not provide any services rather than the marketplace to
            transact at their own cost/risk. The company is not held responsible for the warranties and liabilities
            associated with the products offered on the marketplace, also the company is not the influencer or involved
            between any transactions of yours and third party sellers.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">In India, the marketplace services are available to only selected geographical areas
            and restrictions based on business hours/days of third party sells</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Delivery charges or any other charges during the transactions through the marketplace
            will be informed to the customer when and where it is required.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">Online ordering and Financial Terms </span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c0">This zone is only used to place orders from various Retailers/Partners, Under the
            terms and conditions set out herein.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">NearBy neither controls the Merchants/Retailers , nor owns , sells , resell on its
            own. Any order that you place shall be subject to terms and conditions set out in Terms of Use including but
            not limited to product availability and delivery location serviceability.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">The orders placed on the zone shall be considered as confirmed,in general terms. On
            completion of booking an order, furtherly you will be requested to share certain information with us such as
        </span></p>
    <ul class="c9 lst-kix_hyjzp0usaoaq-0 start">
        <li class="c1 c5 li-bullet-0"><span class="c0">Your first and last name</span></li>
        <li class="c1 c5 li-bullet-0"><span class="c0">Contact number</span></li>
        <li class="c1 c5 li-bullet-0"><span class="c0">Email address</span></li>
    </ul>
    <p class="c1"><span class="c0">It shall be your sole responsibility to bring any corrections in your details to our
            attention.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c3">Please note change or confirmation of order shall be treated as final.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">The customer should make all the payments against any purchases/services shall be
            compulsorily in Indian Rupees which is acceptable in the Republic of India. No other form of currency will
            be accepted on this platform for any kind of purchases/services provided here.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">You can make payments through </span></p>
    <ul class="c9 lst-kix_in5adzbckjmt-0 start">
        <li class="c1 c5 li-bullet-0"><span class="c0">Credit card or debit card or net banking</span></li>
        <li class="c1 c5 li-bullet-0"><span class="c0">Any other RBI approved payment method</span></li>
    </ul>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">NearBy is neither a banking nor a financial service but is absolutely a facilitator
            providing an electronic , automated online electronic payment through which receiving the payment on
            delivery , collection and other transactions on this platform is done by using authorized banking
            network.Herein, NearBy is neither acting as trustees nor acting in a fiduciary capacity with respect to the
            transaction/transaction price.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">The customer has to understand and agree that we act as the Merchants payment agent
            for the limited purpose of accepting payments from customers on behalf of the Merchants , thereafter we are
            responsible to the merchant for the completion of transaction.The customer is held responsible for cash on
            delivery orders or any payments directly to the Merchant for order bookings made using the platform.</span>
    </p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">By the Terms of use and the Pricing terms set forth we will collect the total amount
            in the applicable listing for the Merchants.Please note that we are not responsible for any amount charged
            by your bank related to our collection of total amount and we disclaim all liability in this regard.</span>
    </p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Along with confirmation of order you will be requested to give some details for
            billing such as name, billing address and credit card information either to us or our third party payment
            processor. You agree to pay us for the order placed by you on the Platform, in accordance with these Terms.
            You hereby authorize the collection of amounts by any &nbsp;one of the payment methods described on the
            Platform. If you proceed you will be &nbsp;directed to our third-party payment processor, you may be subject
            to terms and conditions governing use of that third party&#39;s service and their information collection
            practices. Please review such terms and conditions and privacy policy before using the Platform
            services.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">The merchant will be issuing the final tax bill to the customer along with the order
            ,and NearBy is merely collecting the payment on behalf of such merchant.We hold no responsibility for legal
            correctness/validity of the levy of such cases.The Merchant shall be the sole responsible one for any legal
            issue arising on taxes.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">NearBy will not be responsible for any warranty/guarantee of products sold to
            customers,the merchants shall be solely responsible.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">The transaction is bilateral between merchant and buyer,therefore NearBy is not
            liable to charge or deposit any taxes applicable on such transaction.</span></p>
    <p class="c1"><span class="c0">For any kind of refund,replacement,cancellation of purchases the merchant/retailers
            will decide if they would accept any refunds for the products, NearBy is nowhere responsible in such
            cases.</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c2">Services provided</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">You understand that for any deficiency on payment of consideration payable towards
            the goods purchased from the services,will not be held responsible by NearBy.The Terms of services shall be
            governed by the Terms of use and any other terms set out in such document confirm such sale of service.If
            you fail to redeem the goods within the expiry date or in accordance with terms you will not be entitled to
            receive any credit,refund or cash back for the value of goods sold.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">If you use the Platform, you do the same at your own risk.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">You agree that the Services shall be provided by us only during the working hours of
            the relevant Merchants.</span></p>
    <p class="c1 c4"><span class="c3 c10"></span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">License &amp; Access</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c3">NearBy awards you a limited sub-license to access and make personal use of the
            platform but you are not allowed to &nbsp;download/modify except with prior written consent &nbsp;of the
            company. Sub-license do not include/permit any resale or commercial use of marketplace or its
            &nbsp;contents,</span><span class="c0">&nbsp;copying of information for the benefit of another third party;
            or any use of data mining, robots, or similar data gathering and extraction tools. The Marketplace or any
            portion of the Marketplace may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise
            exploited for any commercial purpose without express prior written consent of the Company. You should not
            use any techniques to enclose a trademark, logo or any other information of the marketplace or of the
            company without the express prior written consent of the Company. You should not use any tags or
            &quot;hidden text&quot; utilizing the Company&#39;s name or trademarks without the express prior written
            consent of the Company. You should not access the unauthorized &nbsp;features of the Marketplace, or
            networks connected to the Marketplace or to any server / computer / network / services offered on or through
            the Marketplace, by hacking, &#39;password mining&#39; or any other illegal means.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Hereby you understand that not to host, display, upload, modify, publish, transmit,
            update or share any information belongs to another person and to which you do not have any right;</span></p>
    <p class="c1"><span class="c0">is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic,
            paedophilic, libelous, invasive of another&#39;s privacy, ethnically objectionable,relating or encouraging
            money laundering or gambling, or; harms minors in any way,infringes any patent, trademark, copyright or
            another proprietary/intellectual property rights;violates any law for the time being in force; deceives or
            misleads the addressee about the origin of such messages communicates any information which is grossly
            offensive or menacing in nature;impersonates another person; contains software viruses or any other computer
            code/files/ programs designed to interrupt, destroy or limit the functionality of any computer
            resource;threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with
            foreign states, or prevents investigation of any offense or is insulting any other nation;is misleading or
            known to be false in any way. Any unauthorized use shall automatically terminate the permission or
            sub-license granted by the Company.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">Account &amp; Registration Obligations</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c0">In order to place orders on the marketplace the customer must do registration and
            then log in. The customers should provide current and correct details for all communications related to your
            purchases to the marketplace. During the registration process on marketplace, the company may collect some
            personal identification about you and the information about the pages you visit/access on marketplace, the
            links you click, number of times you access a particular feature/page and any such information. Under the
            privacy policy of the company your information will be collected ,which is incorporated in Terms of Use by
            reference.</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c2">Disputes</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c3">Nearby is not liable to the member for any sort of damages which has occurred on its
            own. Members will reimburse any cost in any defense claim. NearBy is indemnified from any claims of any
            loss/damage or accident affiliated with this agreement.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">Indemnification</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c3">You understand that &nbsp;from and against any claims, damages, obligations, losses,
            liabilities, costs or debt, and expenses &nbsp;you agree to defend NearBy</span><span class="c0">, its
            subsidiaries, affiliates, subcontractors, officers, directors, employees, consultants, representatives and
            agents, are not held responsible. claims arising from includes :</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">(a) usage and access of our platform should be as permitted herein;</span></p>
    <p class="c1"><span class="c0">(b) violation of any of these Terms or any applicable law;</span></p>
    <p class="c1"><span class="c0">(c) violation of any third party right, limitation of &nbsp;any copyright, property,
            or privacy right</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">This indemnification obligation will survive the termination of Your account or use
            of services and the App.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">Governing Law</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c3">The </span><span class="c0">Terms and Conditions shall be governed by Indian Laws
            &nbsp;and shall be subject to the jurisdiction of the courts at the location where the center is located.
            The Members agree and accept that it shall not raise any disputes in respect to the Terms and Conditions in
            any other courts except such courts that are located in the relevant center.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Any dispute arising between the parties relating to the construction, scope,
            operation or effect of these Terms and Conditions, including any question regarding their existence,
            validity or termination, shall be settled by negotiation in the first instance, and if that fails, it will
            be referred to and settled by arbitration in Madurai in accordance with the Indian Arbitration and
            Conciliation Act, 1996 and the award made in pursuance, thereof ,shall be binding on the parties. The number
            of arbitrators shall be one and he / she will be approved by mutual consent. The language of the arbitration
            shall be in English.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">Disclaimer</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c3">We may upgrade the platform constantly, due to which at times &nbsp;Some Functions
            And Features May Not Be Fully Operational.</span><span class="c0">Due to this change may occur in electronic
            distribution of information limitation may be there in providing information due to obtained from multiple
            sources, there may be delays/ omissions/ inaccuracies in the content provided on the platform.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Hence, we do not assure the represented information posted is correct in every case.
            We also disclaim all the liabilities which may arise as a consequence of unauthorized use of credit/debit
            cards. You agree that Third party services are available on this platform, so we may change alliances of
            these Third parties over a period of time to facilitate certain services to you. We do not assure any
            warranty regarding any Third party services nor liable to you nor any consequences or claims arising from
            Third party including any Liability or responsibility for Death,Injury or Impairment experienced by you or
            any Third party.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Hereby You Disclaim And Waive Any Rights And Claims You May Have Against Us With
            Respect To Third Party&#39;s / Merchants Services. Nearby Disclaims And All Liability That May Arise Due To
            Any Violation Of The Food Safety And Standards Act, 2006 And Applicable Rules And Regulations Made
            Thereunder And Such Liability Shall Be Attributable To The Merchant.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1 c4"><span class="c10 c3"></span></p>
    <p class="c1"><span class="c0">We Shall Not Be Liable To You Or Anyone Else For Any Losses Or Injury Arising Out Of
            Or Relating To The Information Provided On The Platform. In No Event Will We Or Our Employees, Affiliates,
            Authors Or Agents Be Liable To You Or Any Third Party For Any Decision Made Or Action Taken By Your Reliance
            On The Content Contained Herein.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">In No Event Will We Be Liable For Any Damages (Including, Without Limitation, Direct,
            Indirect, Incidental, Special, Consequential Or Exemplary Damages, Damages Arising From Personal
            Injury/Wrongful Death, And Damages Resulting From Lost Profits, Lost Data Or Business Interruption),
            Resulting From Any Services Provided By Any Third Party Or Merchant Accessed Through The Platform, Whether
            Based On Warranty, Contract, Tort, Or Any Other Legal Theory And Whether Or Not We Are Advised Of The
            Possibility Of Such Damages.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">Intellectual property</span></p>
    <p class="c1"><span class="c3">NearBy is </span><span class="c0">either the owner of intellectual property rights or
            has the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable right to exercise the
            intellectual property, in the Platform, and in the material published on it.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">The customers using this platform may download extracts,print off one copy for your
            reference and use within your organization of the materials presented on the platform. You are not allowed
            &nbsp;to do modification in digital copies of any materials which you have printed / downloaded in any way,
            Also you are not allowed to use photographs,videos,audio or graphics from any accompanying text.You should
            not use any materials on platform without getting license &nbsp;from us or our licensor for any commercial
            purposes.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">If you fail to do the same under these Terms of use your right to use the platform
            will be ceased immediately and must return/destroy the copies of materials you have made. We process
            information provided by you to us in accordance with our Privacy Policy.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">Third Party Content</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c0">When you access a link that leaves the platform the site you enter is not controlled
            by us, so you &nbsp;understand that we are not responsible for those sites.</span></p>
    <p class="c1"><span class="c3">Although we are under no obligation to do &nbsp;so, we still reserve the right to
            disable links to and /or from third party sites to the platform. </span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c2">Severability</span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1"><span class="c3">The </span><span class="c0">&nbsp;Terms and Conditions for the provisions are
            severable and distinct from the others and, if any such provisions becomes illegal, invalid or unenforceable
            in any respect under law, the legality, validity or enforceability of the remaining provisions shall not be
            affected in any way.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">IP Notice and Take Down Policy</span></p>
    <p class="c1"><span class="c3">NearBy</span><span class="c0">&nbsp;has put in place IP Notice and Take Down Policy
            (&quot;Take Down Policy&quot;) so that intellectual property owners can report the infringement so that the
            infringing products are removed from the site, as they spoil the &nbsp;Buyer and good Sellers
            &nbsp;trust.</span></p>
    <p class="c1"><span class="c0">The intellectual property rights owner can report infringing products or listings
            through Take Down Policy by Notice of infringement in the specified way .Other than the owners, even
            strangers can bring to our notice regarding the same and contact us.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">(Note: NearBy does not and cannot verify that Merchants have the right or ability to
            sell or distribute their listed products. However, NearBy is committed to ensuring that item listings do not
            infringe upon intellectual property rights of third parties once an authorized representative of the rights
            owner properly reports them to NearBy.)</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c2">Contact Us:</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Please contact us for any questions or comments (including all inquiries unrelated to
            copyright infringement) regarding this Platform.</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">In accordance with Information Technology Act, 2000 and rules made there under, the
            name and contact details of the Grievance Officer are provided below:</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">AinGlobe Technologies Private Limited</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Reg Office: Companies Act, 2013 with its registered office at 1/675 - A, Bhagavath
            Singh Colony, Kaithari Nagar, Nilaiyur, Madurai, TamilNadu - 625005</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Phone: +91 - 0452 - &nbsp;</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Email: support@nearbyu.in</span></p>
    <p class="c1 c4"><span class="c0"></span></p>
    <p class="c1"><span class="c0">Time: Mon - Sat (9:00 AM - 06:00 PM)</span></p>
    <hr style="page-break-before:always;display:none;">
    <p class="c1 c4"><span class="c2"></span></p>
    <p class="c1 c4"><span class="c2"></span></p>
    <div>
        <p class="c1 c4"><span class="c12"></span></p>
    </div>
</body>

</html>`;