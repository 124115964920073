import { Box } from '@mui/system'
import React from 'react'
var htmlContent = require('../files/privacy_policy.html')

const PrivacyPolicy = () => {
  return (
    <Box sx={{margin:8}}>
        <div dangerouslySetInnerHTML={{__html: htmlContent}}  />
    </Box>
  )
}

export default PrivacyPolicy
