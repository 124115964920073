import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Button from '@mui/material/Link'
import termsConditions from '../Component/TermsConditions'

const MainFooter = () => {
    return (
        <footer>
            <Box sx={{
                pt: 3,
                px: 3,
                py: 5,
                mt: 30,
                color: 'black'
            }}>
                <Container>
                    <Grid container spacing={0}>
                        <Grid item xs={4} sm={4}>
                            <Box>
                                <Link onClick={() => { window.location.href = "terms_conditions.html" }}>
                                    Terms
                                </Link>
                            </Box>

                        </Grid>
                        <Grid item xs={4} sm={4} align="center">
                            <Box>
                                <Link onClick={() => { window.location.href = "privacy_policy.html" }}>
                                    Privacy
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} align="end">
                            <Box>
                                <Link to={'/Conatct'}>
                                    Contact
                                </Link>
                            </Box>

                        </Grid>
                    </Grid>
                    <Box sx={{
                        textAlign: 'center',
                        pt: 5
                    }}>
                        NearBy Private Limited &reg; {new Date().getFullYear()}
                    </Box>
                </Container>
            </Box>
        </footer>
    )
}

export default MainFooter
