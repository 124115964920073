import { Link } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'
var htmlContent = require('../files/terms_conditions.html.js');

const TermsConditions = () => {
  return (
    <Box sx={{ margin: 8 }}>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </Box>

  );
}

export default TermsConditions
