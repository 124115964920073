import { Box, Typography } from "@mui/material"

export default function AboutUs() {
    return (
        <Box>
            <Typography variant="h4" align="center">
                About us
            </Typography>
            <Box sx={{ marginLeft: 8, marginRight: 8, marginTop: 4, fontWeight: 'regular', lineHeight: 2, fontSize: 20 }} align="justify">
                Daily millions of customers are served by NearBy to fulfill their daily needs by connecting them to the right shop. Through our application you can order your required/favorite things within certain distance, it's absolutely time saving as you will be redirected to right shop according to your search that too you will be notified with the recent and great offers available in the market (including your own locality shops offers ) so that you can grab it soon…… We will connect with all top brands and companies to till the tiny things available in local markets / shops to make sure to get the right things from trusted places and value for your money.
                We have categorized the things right from top brands till local shops near you, so that you can even order things from your favorite “Annachi Kadai “ too……. Iit will be easy to maintain your provision list by tracking your history of orders done here.
                No Delivery charges collected by us, as you deal with your order details during confirmation of list by yourself with shop/partner directly.
            </Box>
        </Box>
    );
}