module.exports = `
<!DOCTYPE html>
<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c1 {
            padding-top: 0pt;
            text-indent: 36pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c12 {
            color: #000000;
            font-weight: 700;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14pt;
            font-family: "Georgia";
            font-style: normal
        }

        .c9 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11pt;
            font-family: "Georgia";
            font-style: normal
        }

        .c3 {
            -webkit-text-decoration-skip: none;
            color: #1155cc;
            font-weight: 400;
            text-decoration: underline;
            text-decoration-skip-ink: none;
            font-size: 12pt;
            font-family: "Georgia"
        }

        .c0 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 12pt;
            font-family: "Georgia";
            font-style: normal
        }

        .c10 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c2 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c7 {
            color: #ff0000;
            text-decoration: none;
            vertical-align: baseline;
            font-style: normal
        }

        .c11 {
            font-size: 20pt;
            font-family: "Verdana";
            font-weight: 700
        }

        .c6 {
            font-size: 12pt;
            font-family: "Georgia";
            font-weight: 400
        }

        .c13 {
            background-color: #ffffff;
            max-width: 540pt;
            padding: 36pt 36pt 36pt 36pt
        }

        .c8 {
            color: inherit;
            text-decoration: inherit
        }

        .c4 {
            height: 11pt
        }

        .c5 {
            text-indent: 36pt
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c13 doc-content">
    <p class="c10"><span class="c11">NearBy</span></p>
    <p class="c2"><span class="c12">Privacy policy</span></p>
    <p class="c2"><span class="c9">AinGlobe Technology Private Limited</span></p>
    <p class="c2"><span class="c0">Effective &amp; last updated Nov 10, 2022</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">NearBy respects the customers privacy and protects your personal information like
            name, address, number etc.. which you share with us. We assure that we follow appropriate standards in
            protecting your privacy on our websites and applications.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c6">Please note that our Privacy Policy forms part of our Terms and conditions available
            at </span><span class="c3"><a class="c8"
                href="https://www.google.com/url?q=http://www.nearbyu.in/terms_conditions.html&amp;sa=D&amp;source=editors&amp;ust=1669056578798552&amp;usg=AOvVaw3h3HgTZr1Iaje9nDsumnUc">Terms
                and Conditions</a></span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Transactions made on this platform should be by a person above the age of 18 years
            only. Person below the age of 18 years should proceed with a transaction under their guardians supervision
            only. If it is not done then the transaction shall be deemed. The legal guardians have to express the
            consent for their use and we disclaim any liability arising from the failure to do so. </span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c6">NearBy &nbsp;may collect your personal data when you create an account with us
            /provide your personal data / use of any related services connected to NearBy / participate in contests and
            surveys, apply for a job, /participate in activities we promote that might require information about
            you.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Computer data is maintained in a manner that identifies your name or contact
            information &nbsp;as personal data. The personal data we collect from you will be used, or shared with third
            parties for further contact in any case.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Retailers who are members/who are transacting with maintaining/creating profile can
            track your activity on NearBy, thus to verify the transactions/used to carry out research and analytics on
            our users&rsquo; demographics /communication that we &nbsp;may send you and provide you with products,
            services/ information we think that would be useful for enhancement of user experience. Determining and
            verifying your eligibility for certain marketing or transaction events and other features of NearBy
            enforcing our terms of use; and/or communicating with you in relation to your account and alerting you to
            the latest developments to enable us to administer any competitions or other offers/promotions which you
            enter into for fraud screening and prevention purposes.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">We may use your personal data to send to you marketing and/or promotional materials
            about us and our services from time to time. You can unsubscribe from receiving the marketing information by
            using the unsubscribe function within the application.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">For data processing activities, we may appoint other organizations to provide our
            products / services to you or to otherwise fulfill contractual arrangements that we have with you.</span>
    </p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">NearBy &nbsp;as an intermediary are responsible for the transaction between you and
            the ultimate retailer, or other CPG/ FMCG brand to book orders or get better deals. To facilitate this
            transaction and fulfill our contractual arrangements with these retailers/providers, we need to share
            customers personal data with them to verify the accuracy of the information you have shared with us while
            issuing your order.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Our services may contain links to third party websites, when you use these features
            your data may be collected/shared depending on the feature. Please be aware that we are not responsible for
            the content or privacy practices of other websites or services to which we link. We do not assure you about
            third-party websites or services. The personal data you choose to provide to or that is collected by these
            third parties is not covered by our Privacy Statement. We strongly recommend you to read such third
            parties&rsquo; privacy statements.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">We may share the provide information under these conditions :</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">(A) The personal data with any person / entity where we believe that such disclosure
            is necessary to:</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2 c5"><span class="c0">(i) Comply with the law or in response to a subpoena, court order, government
            request, or &nbsp; other &nbsp;legal process.</span></p>
    <p class="c2 c5"><span class="c0">(ii) Produce relevant documents or information in connection with litigation,
            arbitration, &nbsp; &nbsp; mediation, adjudication, government or internal investigations, or other legal or
            administrative proceedings;</span></p>
    <p class="c2 c5"><span class="c0">(iii) To protect the rights/ safety / property of NearBy or others;</span></p>
    <p class="c2 c5"><span class="c0">(iv) Or under &nbsp;any applicable law.</span></p>
    <p class="c1"><span class="c0"></span></p>
    <p class="c2"><span class="c0">(B) Personal data about our visitors, customers, or former customers with the
            following types of companies that perform services on our behalf or with whom we have joint marketing
            agreements:</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2 c5"><span class="c0">(i) Non-financial companies such as envelope stuffers, fulfillment service
            providers, payment processors, data processors, customer/support services, etc., and/or</span></p>
    <p class="c2 c5"><span class="c0">(ii) Financial service providers such as companies engaged
            inbanking/payments/facilitating financial transactions.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">You acknowledge that, notwithstanding this Privacy Policy, we have at all times the
            right to disclose your personal data to any legal, regulatory, governmental, tax, law enforcement or other
            authorities pursuant to applicable law and our legal obligations. This may arise from any investigation,
            order, or request by such parties. By law, you agree not to take any action against us for the disclosure of
            your personal data in these circumstances.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">However, no security measures are perfect or impenetrable.If you believe your privacy
            has been breached, please contact us immediately.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2 c4"><span class="c6 c7"></span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">You undertake to treat your password and other confidential information in relation
            to the use of NearBy and its services confidentially, and we disclaim any liability arising from your
            failure to do so.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2 c4"><span class="c7 c6"></span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">You may update any of your personal data we possess by contacting us at
            support@nearbyu.in and we will endeavor to serve your request for update. AinGlobe Technology Private
            Limited, reserves the right to accept or reject requests made for updating of personal data and in case of
            any such refusal we will also endeavor to inform you the reason for such refusal.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">&nbsp;</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">You may communicate your objection to our continual use and/or disclosure of your
            personal data for any of the purposes and in the manner as stated above at any time by contacting us at
            support@nearbyu.in.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">If you do not wish for us to continue using your information, and/or disclose your
            personal data for any of the purposes and in the manner as stated above at any time, you may opt out of
            providing the same by contacting us at support@nearbyu.in. Please note that if you choose not to provide the
            Information we request, you can still visit NearBy but you may not be able to access certain options, offers
            and services. NearBy reserves all legal rights and remedies in such an event.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">We retain personal data only for as long as necessary to provide the services you
            have requested and thereafter for a variety of legitimate legal or business purposes. These might include
            retention periods mandated by law, contract or similar obligations applicable to our business
            operations;</span></p>
    <p class="c2"><span class="c0">for preserving, resolving, defending or enforcing our legal/contractual rights; or
            needed to maintain adequate and accurate business and financial records.</span></p>
    <p class="c2"><span class="c0">If our privacy policy changes in the future, it will be posted here and a new
            effective date will be shown. You should access our privacy policy regularly to ensure you understand our
            current policies. Please reference the privacy policy in your subject line. NearBy will attempt to respond
            to all reasonable concerns or inquiries within 30 business days of receipt.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">If you have an enquiry or a complaint about the way we handle your personal
            information, or to seek to exercise your privacy rights in relation to the personal information we hold
            about you, you may contact our customer grievance officer as follows:</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">By Email: support@nearbyu.in</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">By Mail: Customer Grievance Officer</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">1/675 A, Bhagavath Singh Colony, Kaithari Nagar, Nilaiyur, Madurai, Tamil Nadu -
            625005</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">We will respond to all requests, inquiries or concerns within a reasonable
            period.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
</body>

</html>`;