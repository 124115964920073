import { Routes, Route, } from "react-router-dom";
import './App.css';
import HomeBar from "./Home/HomeBar"
import TermsConditions from "./Component/TermsConditions";
import PrivacyPolicy from "./Component/PrivacyPolicy";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeBar />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default App;
